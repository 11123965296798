<template>
  <div>
    <section id="container">
      <section id="content">
        <section class="studybox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Study Room</h2>
          </div>
          <div class="studybox_h3_box">
            <div class="studybox_h3_box_in">
              <img src="/image/common/top_title_wood_bg02.png" style="float:left;"/>
              <div class="study-room-book-header">
                <span class="book-code">{{ study_room.content_name }}</span>
                <h3 class="book-title">{{ study_room.content_title }}</h3>
              </div>
              <img src="/image/common/top_title_wood_bg04.png" style="float:left;"/>
            </div>
          </div>
          <div class="studybox_h4_box">
            <h4>Listening & Reading</h4>
          </div>
          <section class="study_myinfo_list_area" style="height:90%;">
            <div class="mypage_scroll_content">
              <section class="study_record_box">
                <audio controls v-if="file" id="player" style="display: none;">
                  <source :src="file" type="audio/mp3">
                  Your browser does not support the audio element.
                </audio>
                <!-- content -->
                <div class="record_title_box">
                  <h4>직독직해</h4>
                </div>
                <div class="reading_top_btn_box">
                  <div class="reset_top_left_btn clickable" v-if="translation" @click="resetKrWord()">
                    Reset
                  </div>
                  <div class="reading_top_btn clickable" @click="play">
                    문장듣기
                  </div>
                </div>
                <div v-if="!complete">
                  <div class="reading_word_box01" :class="'item-width-' + translation.content.words.length" v-if="translation">
                    <ul>
                      <li
                        v-for="(word, enWordIndex) in translation.content.words"
                        :key="`word-en-${enWordIndex}`"
                        class="reading_small_word"
                      >{{ word.en.word }}</li>
                    </ul>
                  </div>
                  <div class="reading_word_box02" :class="'item-width-' + translation.content.words.length" v-if="translation">
                    <ul>
                      <li
                        v-for="(word, krWordIndex) in translation.content.words"
                        :key="`word-kr-${krWordIndex}`"
                        class="reading_small_word clickable"
                        @click="displayKrWord(translation, word, krWordIndex)"
                      ><span v-if="word.kr.display" v-text="word.kr.word"/><input v-else type="text" class="reading_input_box_01_round clickable" placeholder="" alt="단어 입력" ></li>
                    </ul>
                  </div>
                </div>
                <div v-else style="margin-top: -140px;">
                  <img src="/image/common/book-content-completed.png" class="book-content-completed">
                </div>
                <!-- //content -->
              </section>
              <div class="quiz_bottom_btn_box">
                <div class="study_result_quiz_num01 clickable" v-if="back" @click="loadContent(back, talkScheduleId, studyRoomId)">{{ back }}</div>
                <img src="/image/sub/reading_bottom_arr.png" alt="슬래쉬 아이콘" style="vertical-align:middle;margin:0 10px 0 10px;">
                <div class="study_result_quiz_num01 clickable" v-if="next" @click="loadContent(next, talkScheduleId, studyRoomId)">{{ next }}</div>
                <div class="study_result_quiz_num01 clickable" v-else @click="completed" style="width: 105px;border-radius: 10px;font-weight: normal;height: 40px;line-height: 38px;">Completed</div>
              </div>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'demo.study-room.content', params: { talkScheduleId: talkScheduleId } }"></footer-navigation>
      </section>
    </section>
  </div>
</template>

<script>

import FooterNavigation from '@/layouts/components/FooterNavigation'
import StudyRoom from '@/models/StudyRoom'

export default {
  components: {
    FooterNavigation
  },
  name: 'Demo.StudyRoom.Content.Type.Translation',
  data () {
    return {
      loading: true,
      complete: false,
      study_room: {},
      file: null,
      translation: {
        content: {
          words: []
        }
      },
      pagination: {},
      next: null,
      back: null,
      totalPage: 0,
      talkScheduleId: typeof this.$route.params.talkScheduleId !== 'undefined' ? this.$route.params.talkScheduleId : '',
      studyRoomId: typeof this.$route.params.studyRoomId !== 'undefined' ? this.$route.params.studyRoomId : '',
      page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      demo: true
    }
  },
  mounted () {
    this.loadContent(this.page, this.talkScheduleId, this.studyRoomId)
  },
  methods: {
    async loadContent (page, talkScheduleId, studyRoomId) {
      this.loading = true
      const searchParams = {}
      searchParams.page = page
      this.$router.replace({ query: searchParams })

      const response = await StudyRoom.demoViewContent({ page: page, talkScheduleId: talkScheduleId, studyRoomId: studyRoomId, demo: this.demo })
      this.translation = response.data.data[0]
      this.file = response.data.data[0].content.file
      $('#player').src = this.file // eslint-disable-line
      $('#player').load() // eslint-disable-line
      this.pagination = response.data.meta.pagination
      this.next = this.pagination.next
      this.back = this.pagination.back
      this.study_room = response.data.meta.study_room
      this.loading = false
    },
    displayKrWord (content, word, wordIndex) {
      word.kr.display = true
      StudyRoom.demoContentIsDone({ talkScheduleId: this.talkScheduleId, studyRoomId: this.studyRoomId, contentId: content.id, wordIndex: wordIndex, demo: this.demo })
    },
    resetKrWord () {
      this.translation.content.words.forEach(function (item) {
        item.kr.display = false
      })
      StudyRoom.demoContentTranslationReset({ talkScheduleId: this.talkScheduleId, studyRoomId: this.studyRoomId, contentId: this.translation.id, demo: this.demo })
    },
    play () {
      $('#player')[0].play(); // eslint-disable-line
    },
    completed () {
      this.complete = true
      const self = this
      setTimeout(function () {
        self.$router.replace({ name: 'demo.study-room.content', params: { talkScheduleId: self.talkScheduleId } })
      }, 2000)
    }
  }
}
</script>

<style scoped>
.reading_word_box01 ul li, .reading_word_box02 ul li {
  margin-right: 2px;
}
.reading_word_box01 ul li:last-child, .reading_word_box02 ul li:last-child {
  margin-right: 0px;
}
</style>
